<template>
  <div class="career--wrapper">
    <banner :banner-text="bannerText" />
    <div class="introduction">
      <div class="title--text">Hi! Nice to Meet You Here!</div>
      <div class="subtitle--text">
        We believe great projects born through passion and commitment. We are seeking people who is
        passionate and commited to create magics that changes the world in a positive way.
      </div>
    </div>
    <div class="career--content">
      <div class="title--text">Jobs Available</div>
      <v-tabs class="tabs--career" v-model="tab" center-active>
        <v-tab href="#tab-0"> Senior Developer </v-tab>
        <v-tab href="#tab-1"> Programmer </v-tab>
        <v-tab href="#tab-2"> Marketing Executive </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-0">
          <p>
            PT. Real Estate Teknologi is currently seeking a Senior PHP Developer to join our team.
            We are looking for someone committed to working on high quality code in a dynamic
            PHP/LAMP environment. If you like being part of a top-shelf engineering team working in
            rapid development cycles and have a passion for mentoring team members, then this could
            be an ideal match for you!
          </p>
          <div class="row">
            <div class="col-sm-8 col-12">
              <div class="subtitle--text">Responsibilities:</div>
              <ol>
                <li>
                  Design, estimate, and code new features. This is primarily a coding position.
                </li>
                <li>Architect new applications.</li>
                <li>Define, document, and enforce engineering standards.</li>
                <li>Perform code reviews of your team members' work.</li>
                <li>Mentor junior engineers.</li>
                <li>Participate in software design discussions.</li>
                <li>Participate in sprint planning and other project management meetings.</li>
                <li>Ensure all code that goes to production is of top quality.</li>
                <li>Ensure existing applications are scaled to meet expected demand.</li>
                <li>Identify and fix gaps in system reliability.</li>
                <li>
                  Continuously improve the software infrastructure, such as the deployment process.
                </li>
              </ol>
              <div class="subtitle--text">Requirements:</div>
              <ol>
                <li>5+ years with object-oriented programming in PHP.</li>
                <li>
                  3+ years with front-end technologies such as JavaScript, JQuery, HTML, and CSS.
                </li>
                <li>Complex SQL queries and database schema design.</li>
                <li>MVC frameworks (Laravel, Zend etc.).</li>
                <li>Working in a collaborative team environment using tools like JIRA or Git.</li>
                <li>Consuming and creating REST services.</li>
                <li>Stable work history and an ability to collaborate effectively.</li>
              </ol>
              <div class="subtitle--text">Desired Experience:</div>
              <ol>
                <li>Scrum, Kanban, or other agile methodologies.</li>
                <li>MongoDB, Node.js experience is a plus.</li>
                <li>Systems integration.</li>
                <li>Distributed systems.</li>
                <li>Service oriented architecture.</li>
                <li>Unit testing.</li>
                <li>Load testing.</li>
                <li>Page load optimization.</li>
              </ol>
            </div>
            <div class="col-sm-4 col-12">
              <a
                href="https://www.jobstreet.co.id/id/job/2041774/sources/2"
                target="_blank"
                class="btn btn-primary"
              >
                Apply For This Position
              </a>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item value="tab-1">
          <p>
            PT. Real Estate Teknologi is currently seeking a Programmer/Developer to join our team.
            We are looking for someone committed to working on high quality code in a dynamic
            PHP/LAMP environment. If you like being part of a top-shelf engineering team working in
            rapid development cycles and have a passion for mentoring team members, then this could
            be an ideal match for you!
          </p>
          <div class="row">
            <div class="col-sm-8 col-12">
              <div class="subtitle--text">Responsibilities:</div>
              <ol>
                <li>
                  Design, estimate, and code new features. This is primarily a coding position.
                </li>
                <li>Participate in software design discussions.</li>
                <li>Participate in sprint planning.</li>
                <li>Ensure all code that goes to production is of top quality.</li>
                <li>Ensure existing applications are scaled to meet expected demand.</li>
                <li>Identify and fix gaps in system reliability.</li>
                <li>
                  Continuously improve the software infrastructure, such as the deployment process.
                </li>
              </ol>
              <div class="subtitle--text">Requirements:</div>
              <ol>
                <li>Good knowledge with object-oriented programming in PHP.</li>
                <li>
                  Good knowledge with front-end technologies such as JavaScript, JQuery, HTML, and
                  CSS.
                </li>
                <li>Good knowledge with SQL queries and database schema design.</li>
                <li>Able to collaborate with team.</li>
                <li>Working in a collaborative team environment using tools like JIRA or Git.</li>
                <li>Consuming and creating REST services.</li>
                <li>Highly motivated to learn new technologies.</li>
              </ol>
            </div>
            <div class="col-sm-4 col-12">
              <a
                href="https://www.jobstreet.co.id/id/job/2019876/sources/2"
                target="_blank"
                class="btn btn-primary"
              >
                Apply For This Position
              </a>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <p>
            PT. Real Estate Teknologi is currently seeking a Marketing Executive to join our team.
            We are looking for someone committed to develop and oversee marketing campaigns to
            promote Rentfix.com's products and services. The role of a marketing executive can
            encompass creative, analytical, digital, commercial and administrative responsibilities.
            Executives to work closely with other employees in areas such as advertising, market
            research, production, sales and dist
          </p>
          <div class="row">
            <div class="col-sm-8 col-12">
              <div class="subtitle--text">Responsibilities:</div>
              <ol>
                <li>Overseeing and developing marketing campaigns</li>
                <li>Conducting research and analysing data to identify and define audiences</li>
                <li>Devising and presenting ideas and strategies and promotional activities</li>
                <li>Compiling and distributing financial and statistical information</li>
                <li>Writing and proofreading creative copy</li>
                <li>Maintaining database and looking at data analytics</li>
                <li>Organising events and product exhibitions</li>
                <li>Updating databases and customer relationship management system</li>
                <li>Coordinating internal marketing and an organisation’s culture</li>
                <li>Monitoring performance</li>
                <li>Managing campaigns on social media</li>
              </ol>
              <div class="subtitle--text">Requirements:</div>
              <ol>
                <li>1 year non-Management & non-Supervisor.</li>
              </ol>
            </div>
            <div class="col-sm-4 col-12">
              <a
                href="https://www.jobstreet.co.id/id/job/2350783/sources/2"
                target="_blank"
                class="btn btn-primary"
              >
                Apply For This Position
              </a>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/utils/page-banner';
//component code
export default {
  components: {
    Banner,
  },
  data: () => ({
    bannerText: 'Karir',
    tab: 'tab-0',
  }),
};
</script>
